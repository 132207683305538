// @flow

import { attr } from 'spraypaint';
import toNumber from 'lodash/fp/toNumber';

import Base, { type BaseModel } from '@kwara/models/src/models/Base';

export const totalAmountWithInteresRate = (amount, percentage) => {
  if (!amount) {
    return 0;
  }

  return percentage ? toNumber(amount) + toNumber(amount * (percentage / 100)) : amount;
};

export const InstantLoan = Base.extend({
  static: {
    jsonapiType: 'instant_loans'
  },
  attrs: {
    productId: attr(),
    amount: attr(),
    installments: attr()
  }
});

export interface InstantLoanType extends BaseModel<InstantLoanType> {
  productId: string;
  amount: number;
  installments: number;
}
