import React from 'react';

import { Redirect, useParams, useHistory } from 'react-router-dom';

import { LoanFormData } from 'LoanSharedTypes';

import { If } from '@kwara/components/src/If/If';
import { Loadable } from '@kwara/components/src/Loadable';
import { MemberType } from '@kwara/models/src/models/Member';
import { LoanProductType } from '@kwara/models/src/models/LoanProduct';
import { useSearchParams } from '@kwara/lib/src/hooks/useSearchParams';
import { useLoanProduct } from '@kwara/models/src/models/request/hooks';
import { StepConfig } from '@kwara/components/src/Wizard/deprecated/Wizard';
import { MemberEligibilityT } from '@kwara/models/src/models/MemberEligibility';
import Wizard, { getSubstepFromMatchParams } from '@kwara/components/src/Wizard/deprecated/Wizard';

import { Store } from '../../models/Store';
import { ROUTES } from '../../routes/routes';
import { useSubmit } from './hooks/useSubmit';
import { useStore } from '../../models/Member';
import { steps as configSteps } from './config';
import { EnterPIN } from '../../components/EnterPIN';
import { useMemberEligibility } from '../../models/request';
import { LoanCompletion } from './components/LoanCompletion';
import { useGetInitialData } from './hooks/useGetInitialData';

type ParamsType = { productId: string; step: string; subStep: string };
type SearchParams = { productId: string };

export type LoanAddPropTypes = {
  baseUrl: string;
  steps?: StepConfig;
  isInstantLoan?: boolean;
  onSubmit?(data: LoanFormData, store: Store): Promise<void>;
};

export const LoanAdd = ({ baseUrl, onSubmit, steps = configSteps, isInstantLoan = false }: LoanAddPropTypes) => {
  const params = useParams<ParamsType>();
  const history = useHistory();

  const store: Store = useStore();
  const searchParams = useSearchParams<SearchParams>();
  const r1 = useLoanProduct(searchParams.get('productId'));
  const getInitialData = useGetInitialData(isInstantLoan);
  const { hasGuarantors, submit } = useSubmit();
  const r2 = useMemberEligibility((store.member as MemberType).id);

  return (
    <If
      condition={store.isLoanAddEnabled}
      do={
        <Loadable {...r2}>
          {(eligibilities: Array<MemberEligibilityT>) => (
            <Loadable {...r1}>
              {(product: LoanProductType) => {
                return (
                  <Wizard
                    steps={steps}
                    baseUrl={baseUrl}
                    history={history}
                    EnterPIN={EnterPIN}
                    analyticsId="LoanAdd"
                    titleId="LoanAdd.Connect.title"
                    currentStep={params.step}
                    onSubmit={submit(store, onSubmit)}
                    cancelReturnsTo={ROUTES.overviewLoans}
                    startId={isInstantLoan ? 'configure' : 'information'}
                    currentSubStep={getSubstepFromMatchParams(params)}
                    initialData={getInitialData(store.member as MemberType, product, eligibilities)}
                    onRenderCustomCompletionScreen={
                      isInstantLoan
                        ? undefined
                        : (_data: LoanFormData) => (
                            <LoanCompletion
                              hasGuarantors={hasGuarantors}
                              onConfirm={() => history.push(ROUTES.overviewLoans)}
                            />
                          )
                    }
                  />
                );
              }}
            </Loadable>
          )}
        </Loadable>
      }
      else={<Redirect to={ROUTES.home} />}
    />
  );
};
