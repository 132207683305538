import { attr, belongsTo, hasOne } from 'spraypaint';
import axios from 'axios';
import get from 'lodash/fp/get';
import isFinite from 'lodash/fp/isFinite';

import { InferredModel } from 'GlobalTypes';

import Base from '../Base';
import Loan, { LoanStates } from '../Loan';
import { LoanApplicationT } from '../LoanApplication';
import { InterestRateT, InterestSettingsT } from '../Loan';
import { LoanProductV2, LoanProductV2Type } from '../LoanProductV2';

type BalanceT = {
  balance: number;
  due: number;
  paid: number;
};

type ScheduleSettingsT = {
  principal_repayment_interval: number;
};

// This points to Mambu's v2 api which has a slightly different payload
// than v1. Ultimately, we will phase out v1 and then can consolidate the FE models,
// and rename this to simply "Loan", jsonapiType: "loans", and endpoint: "/loans".
// For now, Kwara's API uses both so we must mantain both on the FE.
// https://api.mambu.com/?shell#mambu-api-v2-loan-accounts

export const DELETE = 'DELETE';

export const LoanV2 = Base.extend({
  static: {
    jsonapiType: 'loans_v2',
    endpoint: '/loans_v2'
  },
  attrs: {
    state: attr(),
    amount: attr(),
    duration: attr(),

    principal: attr(),
    interest: attr(),
    interestRate: attr(),
    fees: attr(),
    penalties: attr(),
    totalBalance: attr(),

    name: attr(),

    accruedInterest: attr(),
    taxRate: attr(),
    daysLate: attr(),
    daysInArrears: attr(),
    arrearsTolerancePeriod: attr(),

    paymentMethod: attr(),
    futurePaymentsAcceptance: attr(),
    scheduleSettings: attr(),
    interestSettings: attr(),
    product: belongsTo({ type: LoanProductV2 }),
    member: belongsTo(),
    linkedSettlementAccount: attr(),
    loanApplication: hasOne({ type: 'loan_applications' })
  },
  methods: {
    isBridgingProduct() {
      return get('product.isBridgingProduct', this);
    },
    interestRatePercent() {
      return isFinite(get('interestSettings.interestRate', this)) ? this.interestSettings.interestRate / 100 : null;
    },
    isApproved() {
      return [LoanStates.ACTIVE, LoanStates.ACTIVE_IN_ARREARS].includes(this.state.current);
    },
    canRefinance() {
      return (
        [LoanStates.ACTIVE, LoanStates.ACTIVE_IN_ARREARS].includes(this.state.current) && !this.isBridgingProduct()
      );
    },
    async setSettlementAccount(savingsId: string) {
      const url = `${Loan.url(this.id)}/settlement_account`;
      const opts = Loan.fetchOptions();

      if (savingsId === DELETE) {
        return axios.delete(url, opts);
      }

      const data = { data: { attributes: { savings_id: savingsId } } };

      return axios.put(url, data, opts);
    }
  }
});

export interface LoanV2Type extends InferredModel<LoanV2Type> {
  principal: BalanceT;
  interest: BalanceT;
  fees: BalanceT;
  penalties: BalanceT;

  accruedInterest: number;
  taxRate: number;
  daysLate: number;
  daysInArrears: number;
  arrearsTolerancePeriod: number;
  paymentMethod: 'HORIZONTAL' | 'VERTICAL';

  interestSettings: InterestSettingsT;
  interestRate: InterestRateT;
  scheduleSettings: ScheduleSettingsT;
  product: LoanProductV2Type;
  linkedSettlementAccount: { id: string; name: string };
  loanApplication: LoanApplicationT;
}
