import { useCallback } from 'react';

import find from 'lodash/fp/find';
import pick from 'lodash/fp/pick';

import { MemberType } from '@kwara/models/src/models/Member';
import { LoanRemittance } from '@kwara/models/src/models/Remittance';
import { LoanProductType } from '@kwara/models/src/models/LoanProduct';
import { TransactionChannels } from '@kwara/models/src/models/Transactions';
import { MemberEligibilityT } from '@kwara/models/src/models/MemberEligibility';
import { derivedInstallmentMeta } from '@kwara/components/src/pages/LoanAdd/components/Configure/Installments/useInstallments';

const DEFAULT_COLLATERALS = [];
const DEFAULT_COLLECTION_DAY = 1;
const DEFAULT_REPAYMENT_PERIOD = 1;
const DEFAULT_FREQUENCY = 'monthly';
const DEFAULT_REPAYMENT_PERIOD_UNIT = 'MONTHS';

interface ExtendedLoanProductType extends LoanProductType {
  eligibility: MemberEligibilityT;
}

export function useGetInitialData(inInstantLoan: boolean) {
  const getInitialData = useCallback(
    (member: MemberType, product: LoanProductType, eligibilities: Array<MemberEligibilityT>) => {
      const loanRemittance = new LoanRemittance();
      const { unit: repaymentPeriodUnit, value: repaymentPeriod } = product.toRepaymentFrequencyUI();
      const repaymentData =
        repaymentPeriodUnit || repaymentPeriod
          ? { repaymentPeriodUnit, repaymentPeriod }
          : { repaymentPeriodUnit: DEFAULT_REPAYMENT_PERIOD_UNIT, repaymentPeriod: DEFAULT_REPAYMENT_PERIOD };
      const productEligibility = find(e => e.id === product.id, eligibilities);
      (product as ExtendedLoanProductType).eligibility = productEligibility;
      const installmentsData = inInstantLoan
        ? { installments: derivedInstallmentMeta(product.defaultDuration).min }
        : {};

      return {
        member,
        product,
        productName: product.name,
        collaterals: DEFAULT_COLLATERALS,
        disbursementMode: TransactionChannels.cash,
        fullName: member.fullName(),
        remittance: {
          ...loanRemittance,
          frequency: DEFAULT_FREQUENCY,
          collectionDay: DEFAULT_COLLECTION_DAY,
          method: TransactionChannels.cash
        },
        ...installmentsData,
        ...repaymentData,
        ...pick(['firstName', 'standing'], member)
      };
    },
    [inInstantLoan]
  );

  return getInitialData;
}
