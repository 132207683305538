import React from 'react';

import { steps } from './config';
import { useCreateLoan } from './hooks/useCreateLoan';
import { LoanAdd, LoanAddPropTypes } from '../LoanAdd';

export function LoanAddInstant(props: LoanAddPropTypes) {
  const createLoan = useCreateLoan();

  return <LoanAdd isInstantLoan steps={steps} onSubmit={createLoan} {...props} />;
}
